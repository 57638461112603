import React from 'react';

const StatCard = (props) => {
    return (
        <div className="bg-white w-full flex flex-col py-12 rounded-2xl">
            <div className="flex justify-center text-5xl font-bold pb-2">
                {props.numbers}
            </div>
            <div className="flex justify-center font-medium text-gray-600">
                {props.text}
            </div>
        </div>
    )
}

export default StatCard;