import React from 'react';
import ProgramSignupForm from '../components/forms/ProgramSignupForm';

const ProgramSignup = () => {
    return (
        <div>
            <ProgramSignupForm />
        </div>
    )
}

export default ProgramSignup;