import React from 'react';
import ContactUs from '../components/about/ContactUs';

const Contact = () => {
    return (
        <div>
            <ContactUs />
        </div>
    )
}

export default Contact;