import React from "react";

const SubmitButton = (props) => {
    return (
        <div className="flex items-center justify-center w-full mt-4">
            <button onClick={props.onClick} disabled={props.disabled} type="submit"
                    className="focus:outline-none active:bg-black hover:opacity-90 w-48 h-12 text-md font-medium text-white bg-gradient-to-l from-blue-700 to-blue-800 rounded-3xl">
                {props.disabled ? 'Sending...' : 'Submit'}
            </button>
        </div>
    )
}

export default SubmitButton;