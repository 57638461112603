import React from 'react';
import { Link } from 'react-router-dom';

const RedirectButton = (props) => {
    return (
        <div className="mt-8 mb-2">
            <Link to={props.link}>
                <button class="focus:outline-none active:bg-black hover:opacity-90 w-48 h-12 text-md font-medium
                text-white bg-gradient-to-l from-blue-700 to-blue-800 rounded-3xl">
                    {props.btnText}
                </button>
            </Link>
        </div>
    )
}

export default RedirectButton;