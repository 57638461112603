import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FaTimes } from 'react-icons/fa';
import banner from '../../images/banner.png';
import axios from "axios";
import baseUrl from "../../baseUrl";

const EventRegistration = (props) => {

    const [formValue, setFormValue] = useState({
        fullname: '',
        email: '',
        buttonDisabled: false,
        errors: []
    })

    const history = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const errs = validate(formValue);

        if (formValue.buttonDisabled) {
            return;
        }

        if (errs.length > 0) {
            setFormValue({
                ...formValue,
                errors: errs,
                buttonDisabled: false
            });
            return;
        } else {
            setFormValue({
                ...formValue,
                errors: errs,
                buttonDisabled: true
            });
            let data = {
                fullname: formValue.fullname,
                email: formValue.email
            }
            axios.post(baseUrl+'/api/v1/events/897af055-f0a5-4d5b-8eed-fdde6f504d85/register', data)
                .then((response) => {
                    history("/event/registered");
                }).catch((err) => {
                    console.log('FAILED', err);
                });
        }
    }

    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    const validate = (formValue) => {
        const errors = [];

        if (formValue.fullname.length === 0) {
            errors.push("Name can't be empty");
        }
        if (formValue.email.length === 0) {
            errors.push("Email can't be empty");
        }
        if (formValue.email.split("").filter(x => x === "@").length !== 1) {
            errors.push("Email should contain @");
        }

        return errors;
    }

    return (
        <div className="bg-gradient-to-b from-purple-600 to-indigo-700 w-full">
            <div className="w-full flex flex-col items-center justify-center my-6 pt-2">
                <img src={banner} className="sm:max-2-sm lg:max-w-3xl mb-6 rounded-xl" alt="hungary" />
                <div className="top-40 bg-white shadow rounded py-12 lg:px-28 px-8">
                    <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">Sign up for a Free Webinar</p>
                    <form>
                        <div className="md:flex items-center mt-12">
                            <div className="md:w-72 flex flex-col">
                                <label className="text-base font-semibold leading-none text-gray-800">Full Name</label>
                                <input name="fullname" type="name" value={formValue.fullname} onChange={handleChange} tabIndex={0} arial-label="Please enter name" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Firstname Lastname" />
                            </div>
                            <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 sm:mt-8">
                                <label className="text-base font-semibold leading-none text-gray-800">Email Address</label>
                                <input name="email" value={formValue.email} onChange={handleChange} tabIndex={0} arial-label="Please enter email address" type="name" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Enter your email" />
                            </div>
                        </div>
                        <p className="text-xs leading-3 text-gray-600 my-4">By clicking submit you agree to our terms of service, privacy policy and how we use data as stated</p>
                        <div>
                            {formValue.errors.map(err => (
                                <div className="flex text-center">
                                    <FaTimes className="text-red-600 text-xl pt-1" />
                                    <p key={err} className="pb-1">{err}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center justify-center w-full mt-4">
                            <button onClick={handleSubmit} disabled={formValue.buttonDisabled} type="submit" class="focus:outline-none active:bg-black hover:opacity-90 w-48 h-12 text-md font-medium text-white bg-gradient-to-l from-blue-700 to-blue-800 rounded-3xl">
                                {formValue.buttonDisabled ? 'Sending...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EventRegistration;