import React from 'react';

const Input = (props) => {
    let containerClass = '';
    if (props.size == 'half') {
        containerClass = 'md:w-72 flex flex-col md:mx-3 md:mt-0 sm:mt-8';
    } else {
        containerClass = 'md:w-150 flex flex-col md:mx-3 md:mt-0 sm:mt-8';
    }

    return (
        <div className={containerClass}>
            <label className="text-base font-semibold leading-none text-gray-800">{props.label}</label>
            <input name={props.name} type={props.type} value={props.value} onChange={props.onChange} tabIndex={0}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700
                   mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400"
                   placeholder={props.placeholder} />
        </div>
    );
}

export default Input;