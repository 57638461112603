import React, {useState} from 'react';
import './price-style.css';

const PricingFeatures = (props) => {

    const [plan] = useState(props.plan);
    const features = plan.features;
    return (
        <div>
            {features.map(feature => (
                (() => {
                    if (plan.name==="Ultimate") {
                        return (
                            <li class="flex items-center mb-2.5">
                                <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" class="mr-4" alt="check-mark" />
                                <p class="pricing-premium-txt">{feature.feature}</p>
                            </li>
                        )
                    } else {
                        if (feature.includes) {
                            return (
                                <li class="flex items-center mb-2.5">
                                    <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" class="mr-4" alt="check-mark" />
                                    <p class="pricing-starter-txt-includes">{feature.feature}</p>
                                </li>
                            )
                        } else {
                            return (
                                <li class="flex items-center mb-2.5">
                                    <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" class="opacity-0 mr-4" alt="check-mark" />
                                    <p class="pricing-starter-txt-notincludes">{feature.feature}</p>
                                </li>
                            )
                        }
                    }
                })()
            ))}
        </div>
    )
}

export default PricingFeatures;
