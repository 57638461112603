import React, { useState } from "react";
import Header from './Header';
import ProgramList from './ProgramList';
import programs_data from "../../data/programs_data";
import CtaGeneral from '../marketing/CtaGeneral';
import Testimonials from "../testimonial/Testimonials";
import CtaContact from "../marketing/CtaContact";
import cta_newsletter from "../../data/cta_newsletter";

const ProgramsContainer = () => {

    const [state] = useState({
        programs: programs_data,
        cta_newsletter: cta_newsletter
    });

    return (
        <div>
            <Header />
            <ProgramList 
                programs={state.programs}
            />
            <CtaContact />
            <Testimonials />
            <CtaGeneral
                cta={state.cta_newsletter}
            />
        </div>
    )
}

export default ProgramsContainer;