import React from 'react';

const CtaGeneral = (props) => {
    return (
        <div class="mx-auto sm:px-10 md:px-20 my-6 bg-blue-900 py-6">
            <div class="flex flex-col justify-center items-center pt-10 pb-10 text-white">
                <div class="md:text-5xl text-4xl font-extrabold text-center leading-snug lg:w-3/4">
                    {props.cta.title}
                </div>
                <div class="flex sm:px-2 lg:px-52 justify-center mt-8 font-medium text-xl text-center">
                    {props.cta.description}
                </div>
                <div class="flex justify-center items-center mt-8">
                    <a href={props.cta.link} rel="noreferrer" target="_blank">
                        <button class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:opacity-90 w-48 h-12 text-lg font-medium text-black bg-gradient-to-l from-indigo-300 to-indigo-400 rounded">{props.cta.actionText}</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CtaGeneral;;