import ugradImg from '../../images/programs/ugrad.png';
import usFlag from "../../images/flags/united-states.png";

const ugrad_details = {
    id: 2,
    idName: "ugrad",
    name: "Global UGRAD",
    subtitle: "Mentorship program",
    shortDescription: "Study a semester in the US for free",
    requirements: ["English language proficiency", "At least 18 years old", "Current university student in Uzbekistan"],
    flags: [usFlag],
    features: {
        country: "United States",
        target: "Undergraduate students",
        length: "2-5 weeks",
        deadline: "December 20"
    },
    fullDescription: "The Global Undergraduate Exchange Program (Global UGRAD) provides a diverse group of emerging student leaders with a scholarship for one semester of non-degree academic study at a U.S. college or university. The program is sponsored by the U.S. Department of State and aims to recruit undergraduate students from underrepresented backgrounds who have not had other opportunities to study in the United States. Successful applicants can expect an in-depth exposure to U.S. society, culture, and academic institutions, as well as opportunities to enhance their professional skills. UGRAD Mentorship program aims to assist undergraduate students to apply for Global UGRAD scholarship program. Students who successfully finish the mentorship program are expected to become finalists in the UGRAD program.",
    content: [
        {
            "id": 1,
            "title": "2-4 live meetings",
            "description": "During the live meetings, your mentor will explain to you the program requirements, procedures, and share top secrets of winning the UGRAD program."
        },
        {
            "id": 2,
            "title": "Essay review",
            "description": "You will receive exclusive feedback for your essays. Feedback will include ways to improve your essay, to make it more coherent, to write your narrative meaningfully, and also notes about your language mistakes."
        },
        {
            "id": 3,
            "title": "Application review",
            "description": "Your mentor will review your application and give his feedback before you submit it."
        },
        {
            "id": 4,
            "title": "Tips for receiving Recommendation Letters",
            "description": "You will hear top tips about how to get the best recommendation letters from your teachers or manager."
        },
        {
            "id": 5,
            "title": "Interview preparation",
            "description": "Your mentor will also prepare you for the UGRAD interview which takes place during the second round of the program."
        },
        {
            "id": 6,
            "title": "Free support with other scholarship programs",
            "description": "If you decide to apply for other scholarship programs and if you have previously signed up for our mentorship program, then we will provide you with free assistance with your application."
        }
    ],
    schedule: [
        {
            week: "Week 1",
            topic: "Introduction",
            description: "Create an action plan, start collecting documents"
        },
        {
            week: "Week 2",
            topic: "Working on Application",
            description: "Starting your application, writing and revising essays, learning about application tips"
        },
        {
            week: "Week 3",
            topic: "Working on Application (continue)",
            description: "Starting your application, writing and revising essays, learning about application tips"
        },
        {
            week: "Week 4",
            topic: "Final Review",
            description: "Revising and submitting the application"
        }
    ],
    pricing: [
        {
            name: "Starter",
            features: [
                {
                    feature: "2x live meetings with expert",
                    includes: true
                },
                {
                    feature: "Application Review",
                    includes: true
                },
                {
                    feature: "Top Tips for Essays",
                    includes: true
                },
                {
                    feature: "Essay Feedback and Correction",
                    includes: false
                },
                {
                    feature: "Interview Preparation",
                    includes: false
                },
                {
                    feature: "Access to database of 500+ scholarships",
                    includes: false
                },
                {
                    feature: "Free Assistance with other Programs",
                    includes: false
                },
            ],
            price: 21
        },
        {
            name: "Ultimate",
            features: [
                {
                    feature: "2x live meetings with expert",
                    includes: true
                },
                {
                    feature: "Application Review",
                    includes: true
                },
                {
                    feature: "Top Tips for Essays",
                    includes: true
                },
                {
                    feature: "Essay Feedback and Correction",
                    includes: true
                },
                {
                    feature: "Interview Preparation",
                    includes: true
                },
                {
                    feature: "Access to database of 500+ scholarships",
                    includes: false
                },
                {
                    feature: "Free Assistance with other Programs",
                    includes: true
                },
            ],
            price: 99
        }
    ],
    mentor: {
        name: "Rustam Temirov",
        title: "Lead Mentor",
        achievements: [
            "Global UGRAD Winner 2020",
            "Google Developers Group Samarkand Organizer",
            "Winner of Stipendium Hungaricum scholarship 2020"
        ]
    },
    image: ugradImg
}

export default ugrad_details;