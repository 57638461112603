import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaThumbtack } from "react-icons/fa";

const SingleProgram = (props) => {

    const [program] = useState(props.program)

    return (
        <div class="flex justify-center w-sm center-center" style={{maxWidth: '580px'}}>
            <div class="rounded-lg sm:mx-0 md:mx-2 overflow-hidden shadow-xl bg-white w-full">
                <div class="relative overflow-hidden pb-60">
                    <img
                    class="absolute h-full w-full object-cover object-center"
                    src={program.image}
                    alt=""
                    />
                </div>
                <div class="relative">
                    <div class="py-10 px-8">
                        <h3 class="text-2xl font-bold">{program.name}</h3>
                        <div class="text-gray-600 text-sm font-medium flex mb-4 mt-2">
                            <p>{program.subtitle}</p>
                        </div>
                        <p class="leading-7">
                            {program.shortDescription}
                        </p>
                        <div className="pt-3">
                            <p className="font-medium">Requirements</p>
                            <ul>
                                {program.requirements.map(item => (
                                    <li className="pt-1">
                                        <div className="flex">
                                            <FaThumbtack color="red" className="pt-1 pr-1" size={20} /> {item}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="mt-10 flex justify-between items-center">
                            <div className="flex justify-evenly">
                                {program.flags.map(flag => (
                                    <div className="pr-2">
                                        <img className="w-8 h-8" src={flag} alt="flag" />
                                    </div>
                                ))}
                            </div>
                            <Link to={`/programs/${program.idName}`} class="flex items-center">
                                <p class="mr-4">Read more</p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.125"
                                    height="13.358"
                                    viewBox="0 0 14.125 13.358"
                                >
                                    <g transform="translate(-3 -3.293)">
                                    <path
                                        id="Path_7"
                                        data-name="Path 7"
                                        d="M14.189,10.739H3V9.2H14.189L9.361,4.378l1.085-1.085,6.679,6.679-6.679,6.679L9.361,15.566Z"
                                        fill="#1d1d1d"
                                        fill-rule="evenodd"
                                    ></path>
                                    </g>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleProgram;