import React, { useState } from "react";

const EventsList = (props) => {
    return (
        <div>
            <h1>{props.title}</h1>
            <div>
                Here will be list of events
            </div>
        </div>
    )
}

export default EventsList;