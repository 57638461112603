import React, { useState } from 'react';
import AboutSection from '../components/about/AboutSection';
import OurTeam from '../components/about/OurTeam';
import CtaGeneral from '../components/marketing/CtaGeneral';
import Feature from '../components/marketing/Feature';
import cta_getstarted from '../data/cta_getstarted';

const About = () => {

    const [state] = useState({
        cta_getstarted: cta_getstarted
    })

    return (
        <div>
            <div className="mx-auto py-12 bg-gray-80 sm:px-4 md:px-28 lg:px-40">
                <AboutSection />
                <Feature />
                <OurTeam />
            </div>
            <CtaGeneral cta={state.cta_getstarted} />
        </div>
    );
}

export default About;