import React from 'react';
import oxfordImg from '../../images/oxford-campus.jpg';
import storyImg from '../../images/our-story.png';

const AboutSection = () => {
    return (
        <div class="">
            <div class="lg:w-10/12 w-full">
                <p class="font-normal text-sm leading-3 text-indigo-700 hover:text-indigo-800 cursor-pointer">About</p>
                <h2 class="xl:w-8/12 lg:w-10/12 w-full font-bold text-gray-800 lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2">We are here to make scholarships accessible for everyone</h2>
                <p class="font-normal text-base leading-6 text-gray-600 mt-6">
                    Globstudy was founded in December, 2020 with a vision to equalize the top university admissions for students
                    coming from Uzbekistan and other CIS countries. Our mission is to equip every student accross CIS countries 
                    to overcome barriers in the process of academic scholarship or university applications.
                </p>
            </div>

            <div class="lg:mt-14 sm:mt-10 mt-12">
                <img class="lg:block hidden w-full rounded-lg" src={oxfordImg} alt="Oxford Campus" />
                <img class="lg:hidden sm:block hidden w-full rounded-lg" src={oxfordImg} alt="Oxford Campus" />
            </div>

            <div class="lg:mt-16 sm:mt-12 mt-16 flex lg:flex-row justify-between flex-col lg:gap-8 gap-12">
                <div class="w-full xl:w-5/12 lg:w-6/12">
                    <h2 class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800">Our Story</h2>
                    <p className="text-base leading-6 text-gray-600 mt-4 lg:w-12/12 w-full">A note from our CEO, Jafarbek Ulmasov</p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-4 italic">
                        "When I was applying to the world's top universities, I couldn't help but wonder: 
                        How on earth could I ever afford any of these $60,000-tuition-fee schools? How 
                        could a high schooler from Uzbekistan who had the same dream as millions of high 
                        schoolers in the US and beyond, compete with the world's smartest students in the scholarship application pool?
                    </p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 italic">
                        At high school, I didn't have access to college advisor who could provide me with guidance, 
                        nor did I know about undergraduate scholarships available to us. The day I found out about 
                        scholarships, I immediately quitted everything, and started working on my application, taking IELTS and SAT tests.
                    </p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 italic">
                        Again, I didn't have anyone to guide me through the rigirous application process. 
                        Eventually, I ended up applying for Duke Kunshan University, where I am now completing 
                        BS in Data Science. When I received a letter of acceptance with full scholarship, 
                        I was blown away with excitement. But I was equally stunned that the opportunities 
                        I had access to were not accessible by the wider student community. I wanted my peers 
                        to know about these opportunities, and seize them.
                    </p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 italic">
                        I founded Globstudy in December, 2020 with a vision to equalize the world leading university 
                        admissions and scholarships playing field. Globstudy is the launching pad for students from 
                        Uzbekistan and CIS countries. Our team and knowledge base informs, guides and empowers students 
                        like you to apply for international scholarships.
                    </p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 italic">
                        At Globstudy we are incredible proud of our talented students and mentors, and 
                        invite you or your child to join us, and pursue your/their own college dream with no barriers."
                    </p>
                    <h1 className="text-base font-medium leading-6 text-gray-700 mt-4 lg:w-12/12 w-full">Jafarbek Ulmasov</h1>
                    <h1 className="text-base font-medium leading-6 text-gray-600 mt-1 lg:w-12/12 w-full">Co-founder and CEO</h1>
                    <p className="text-base font-medium leading-6 text-gray-600 mt-1 lg:w-12/12 w-full">Globstudy</p>
                </div>
                <div class="lg:flex items-center w-full lg:w-1/2">
                    <img class="lg:block hidden w-full" src={storyImg} alt="pins" />
                    <img class="lg:hidden sm:block hidden w-full h-3/4" src={storyImg} alt="pins" />
                    <img class="sm:hidden block w-full" src={storyImg} alt="pins" />
                </div>
            </div>
        </div>
    )
}

export default AboutSection;