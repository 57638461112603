import React from 'react';

const ThankYouPage = () => {
    return (
        <div className="bg-gradient-to-b from-purple-600 to-indigo-700 w-full">
            <div className="w-full flex items-center justify-center my-12 pt-2">
                <div className="top-40 bg-white shadow rounded-xl py-16 lg:px-28 px-12 flex flex-col">
                    <p className="md:text-4xl text-xl font-bold leading-7 text-center text-gray-700">Your Registration has been Successful!</p>
                    <p className="flex text-center justify-center pt-6">Join Our Telegram to receive a reminder and Zoom link for the webinar.</p>
                    <div className="flex justify-center mt-8 mb-2">
                    <a href="https://t.me/globstudy" rel="noreferrer" target="_blank">
                        <button class="focus:outline-none active:bg-black hover:opacity-90 w-48 h-12 text-md font-medium text-white bg-gradient-to-l from-blue-700 to-blue-800 rounded-3xl">
                            Join Our Telegram
                        </button>
                    </a>
                    </div>
                    <p className="flex justify-center text-xs leading-3 text-gray-600 mt-4">
                        You will also receive Zoom invitation via email that you provided.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ThankYouPage;