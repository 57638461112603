import React from 'react';
import logo from '../../images/logo-black.svg';
import { FaInstagram, FaFacebookF, FaTelegram } from 'react-icons/fa';

const Footer = () => {
    return (
        <div>
        <div className="pt-16">
        <div className="w-full border-gray-300 border-t lg:w-11/12 md:w-11/12 lg:mx-auto md:mx-auto">
            <div className="container mx-auto py-12">
                <div className="xl:flex lg:flex md:flex pt-6">
                    <div className="sm:w-full xl:w-3/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0">
                        <a href="/">
                            <img src={logo} className="lg:w-5/12 sm:w-10/12" alt="logo" />
                        </a>
                        <p className="pt-8 sm:w-full lg:w-3/4 text-base text-gray-600 hover:text-gray-700 mb-4">
                            Globstudy is a leading scholarship consulting agency. 
                            We have helped dozens of students gain admission to their 
                            dream universities with full scholarships, and we can do 
                            the same for you.
                        </p>
                    </div>
                    <div className="w-full xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                        <ul>
                            <li className="text-gray-800 font-bold text-xl mb-6">Programs</li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/programs/accelerator">Accelerator</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/programs/ugrad">Ugrad Mentorship</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/programs/turkiye">Turkey Scholarships</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/programs/general">General Mentorship</a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                        <ul>
                            <li className="text-gray-800 font-bold text-xl mb-6">Getting Started</li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-5">
                                <a href="/">About Us</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-5">
                                <a href="/">Guidelines and how to</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-5">
                                <a href="/">Quote from the best</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-5">
                                <a href="/">How to start a blog</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-5">
                                <a href="/">Quote from the best</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-5">
                                <a href="/">Guidelines and how to</a>
                            </li>
                        </ul>
                    </div> */}
                    <div className="w-full xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                        <ul>
                            <li className="text-gray-800 font-bold text-xl mb-6">Resources</li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="https://t.me/globstudy">Scholarships Blog</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/about">About Us</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/contact">Contact Us</a>
                            </li>
                            <li className="text-base text-gray-600 hover:text-gray-700 mb-4">
                                <a href="/">Events (coming soon)</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="xl:flex flex-wrap justify-between xl:mt-24 mt-16 pb-6 pl-3 sm:pl-0">
                    <div className="sm:w-full xl:w-2/6 mx-auto lg:mx-0 xl:mx-0 mb-6 xl:mb-0">
                        <p className="text-gray-800 text-base">© 2022 Globstudy. All Rights Reserved</p>
                    </div>
                    <div className="sm:w-full xl:w-2/6 mx-auto lg:mx-0 xl:mx-0 mb-6 lg:mb-0 xl:mb-0">
                        <ul className="xl:flex lg:flex md:flex sm:flex justify-around">
                            <li className="text-gray-800 hover:text-gray-900 text-base mb-4 sm:mb-0 hover:underline">
                                <a href="/">Terms of service</a>
                            </li>
                            <li className="text-gray-800 hover:text-gray-900 text-base mb-4 sm:mb-0 hover:underline">
                                <a href="/">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-11/12 xl:w-1/6 lg:w-1/6 sm:w-11/12 mx-auto lg:mx-0 xl:mx-0 mb-6 lg:mb-0 xl:mb-0 mt-8 lg:mt-8 xl:mt-0">
                        <div className="flex justify-start sm:justify-start xl:justify-end space-x-6 pr-2 xl:pr-0 lg:pr-0 md:pr-0 sm:pr-0">
                            <div>
                                <a href="https://www.facebook.com/globstudy.uz">
                                    <FaFacebookF className="w-5 h-5 text-gray-700" />
                                </a>
                            </div>
                            <div>
                                <a href="https://www.instagram.com/globstudy/">
                                    <FaInstagram className="w-5 h-5 text-gray-700" />
                                </a>
                            </div>
                            <div>
                                <a href="https://t.me/globstudy">
                                    <FaTelegram className="w-5 h-5 text-gray-700" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}

export default Footer;