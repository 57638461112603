import React, { useState } from "react";
import ProgramSchedule from "./ProgramSchedule";
import ProgramContent from "./ProgramContent";

const ProgramDetails = (props) => {

  const [program] = useState(props.program)

  return (
    <div class="mx-auto py-12 bg-gray-80 sm:px-4 md:px-28 lg:px-40">
      <div class="mx-auto w-full flex items-center md:flex-row flex-col justify-between px-2 lg:px-10">
        <div class="flex flex-col justify-start items-start lg:w-6/12 px-2 lg:px-0">
          <div>
            <p class="lg:text-sm text-xs text-gray-600 dark:text-gray-300 font-medium leading-none">{program.subtitle}</p>
          </div>
          <div class="md:mt-3">
            <p class="text-gray-800 dark:text-white lg:text-4xl text-3xl font-extrabold leading-9">{program.name}</p>
          </div>
          <div class="md:mt-3">
            <p class="lg:text-base text-sm leading-normal text-gray-600 dark:text-gray-300">{program.shortDescription}</p>
          </div>
          <div class="grid grid-cols-2 mt-8 gap-y-6">
            <div>
              <p class="text-gray-800 dark:text-white text-sm lg:text-base font-medium leading-none">Country: <span class="font-semibold md:font-medium">{program.features.country}</span></p>
            </div>
            <div>
              <p class="text-gray-800 dark:text-white text-sm lg:text-base font-medium leading-none">Target: <span class="font-semibold md:font-medium">{program.features.target}</span></p>
            </div>
            <div>
              <p class="text-gray-800 dark:text-white text-sm lg:text-base font-medium leading-none">Length: <span class="font-semibold md:font-medium">{program.features.length}</span></p>
            </div>
            <div>
              <p class="text-gray-800 dark:text-white text-sm lg:text-base font-medium leading-none">Deadline: <span class="font-semibold md:font-medium">{program.features.deadline}</span></p>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center md:w-6/12 sm:w-full mt-10 md:mt-0">
          <img class="w-full" src={program.image} alt="laptops" />
        </div>
      </div>
      <div class="mx-auto w-full justify-between items-start mt-12 px-2 lg:px-4">
        <div class="flex flex-col justify-start items-start">
          <div>
            <h2 class="text-gray-800 dark:text-white lg:text-3xl text-2xl font-bold leading-7">The details</h2>
          </div>
          <div class="mt-8">
            <p class="text-gray-800 dark:text-white lg:text-base text-sm leading-normal">
              {program.fullDescription}
            </p>
          </div>
          <ProgramContent content={program.content} />
          <ProgramSchedule schedule={program.schedule} />
        </div>
      </div>
    </div>
  );
}

export default ProgramDetails;