import React from 'react';
import EventRegistration from '../components/forms/EventRegistration';

const Event = () => {
    return (
        <div>
            <EventRegistration />
        </div>
    )
}

export default Event;