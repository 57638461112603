import React from 'react';
import StatCard from './StatCard';

const Stats = () => {
    return (
        <div className="bg-indigo-700 px-4 py-4">
            <div className="flex flex-col justify-center py-10 m-6">
                <div className="text-white font-black text-5xl flex justify-center pb-4">
                    By the numbers
                </div>
                <div className="flex justify-center text-white font-normal text-lg">
                    You get results with us. No matter what.
                </div>
            </div>
            <div className="w-full lg:w-8/12 mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-10">
                    <StatCard numbers="30+" text="Successful students" />
                    <StatCard numbers="$2mln" text="Scholarship received" />
                    <StatCard numbers="500+" text="Scholarships in database" />
                    <StatCard numbers="10+" text="Expert mentors" />
                </div>
            </div>
        </div>
    )
}

export default Stats;