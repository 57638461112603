import React from "react";
import EventsContainer from "../components/events/EventsContainer";

const Events = () => {
    return (
        <div>
            <EventsContainer />
        </div>
    )
}

export default Events;