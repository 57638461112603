import React, { useState } from 'react';
import ProgramDetails from './ProgramDetails';
import CtaContact from '../../marketing/CtaContact';
import PriceSection from './PriceSection';
import Feature from '../../marketing/Feature';

const ProgramContainer = (props) => {

    const [state] = useState({
        programDetails: props.program
    })

    return (
        <div>
            <ProgramDetails program={state.programDetails} />
            <PriceSection program={state.programDetails} />
            <div class="mx-auto pb-16 bg-gray-80 sm:px-2 md:px-28 lg:px-40">
                <Feature />
            </div>
            <CtaContact />
        </div>
    )
}

export default ProgramContainer;