import accelerator_details from "./programs/accelerator_details";
import ugrad_details from "./programs/ugrad_details";
import turkey_details from "./programs/turkey_details";

const programs_data = [
    accelerator_details,
    ugrad_details,
    turkey_details,
]

export default programs_data;