import React from 'react';
import { FaUniversity, FaGraduationCap, FaPenNib, FaSearchDollar } from "react-icons/fa";

const OurServices = () => {

    const iconSize = 35;

    return (
        <div class="pt-14">
            <div class="py-12 md:px-20 sm:px-2">
                <div className="flex flex-col justify-center pb-12">
                    <div className="font-black text-5xl flex justify-center pb-4">
                        What we offer
                    </div>
                </div>
                <div class="max-w-8xl mx-auto">
                    <div tabindex="0" aria-label="group of cards" class="focus:outline-none flex flex-wrap items-center justify-center sm:justify-between">
                        <div tabindex="0" aria-label="card 1" class="focus:outline-none flex flex-col items-center py-6 md:py-0 px-6 w-full sm:w-1/2 md:w-1/4">
                            <div class="w-20 h-20 relative ml-6">
                                <div class="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
                                <div class="text-white absolute bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                    <FaUniversity size={iconSize} />
                                </div>
                            </div>
                            <h4 tabindex="0" class="focus:outline-none text-lg font-medium leading-6 text-gray-800 text-center pt-5">
                                US, UK, and EU university<br />
                                admissions support
                            </h4>
                        </div>
                        <div   tabindex="0" aria-label="card 2" class="focus:outline-none flex flex-col items-center py-6 md:py-0 px-6 w-full sm:w-1/2 md:w-1/4">
                            <div class="w-20 h-20 relative ml-6">
                                <div class="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
                                <div class="text-white absolute bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                    <FaGraduationCap size={iconSize} />
                                </div>
                            </div>
                            <h4 tabindex="0" class="focus:outline-none text-lg font-medium leading-6 text-gray-800 text-center pt-5">
                                Postgrad admissions<br />
                                support
                            </h4>
                        </div>
                        <div tabindex="0" aria-label="card 3" class="focus:outline-none flex flex-col items-center py-6 md:py-0 px-6 w-full sm:w-1/2 md:w-1/4">
                            <div class="w-20 h-20 relative ml-6">
                                <div class="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
                                <div class="text-white absolute bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                    <FaPenNib size={iconSize} />
                                </div>
                            </div>
                            <h4 tabindex="0" class="focus:outline-none text-lg font-medium leading-6 text-gray-800 text-center pt-5">
                                Essay Review<br />
                                by Writing Experts
                            </h4>
                        </div>
                        <div tabindex="0" aria-label="card 4" class="focus:outline-none flex flex-col items-center py-6 md:py-0 px-6 w-full sm:w-1/2 md:w-1/4">
                            <div class="w-20 h-20 relative ml-6">
                                <div class="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
                                <div class="text-white absolute bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                    <FaSearchDollar size={iconSize} />
                                </div>
                            </div>
                            <h4 tabindex="0" class="focus:outline-none text-lg font-medium leading-6 text-gray-800 text-center pt-5">
                                Access to database<br />
                                of over 500 scholarships
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurServices;