import React from "react";
import duke_campus from '../../images/duke-campus.jpg';

const Header = () => {

    return (
        <div class="max-w-100 mx-auto h-96">
            <div class="flex justify-center items-center bg-blend-darken bg-fixed w-full h-full" 
            style={{
                backgroundImage: `url(${duke_campus})`,
                color: 'white',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                boxShadow: 'inset 0 0 0 2000px rgba(1,33,105,0.3)'
            }}>
                <div class="lg:text-6xl text-5xl font-black tracking-normal drop-shadow text-center">
                    Go beyond your potential with us!
                </div>
            </div>
        </div>

        
    )
}

export default Header;