import React, { useState } from 'react';
import ProgramContainer from "../components/program/program-details/ProgramContainer";
import {useParams} from "react-router-dom";
import programs_data from "../data/programs_data";

const ProgramDetails = () => {

    const { idName } = useParams();

    const programData = programs_data.find(o => o.idName === idName);
    console.log(programData);
    console.log(idName);

    const [state] = useState({
        program: programData,
    })

    return (
        <div>
            <ProgramContainer program={state.program} />
        </div>
    )
}

export default ProgramDetails;