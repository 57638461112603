import React, { useState } from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import Input from "../core/form/Input";
import TextArea from "../core/form/TextArea";
import SubmitAgreement from "../core/form/SubmitAgreement";
import ErrorsDisplay from "../core/form/ErrorsDisplay";
import SubmitButton from "../core/form/SubmitButton";
import FormTitle from "../core/form/FormTitle";
import axios from "axios";
import baseUrl from "../../baseUrl";

const FreeConsultation = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    let term = searchParams.get("utm_source");

    const [formValue, setFormValue] = useState({
        fullname: '',
        phone: '',
        location: '',
        email: '',
        statement: '',
        buttonDisabled: false,
        errors: []
    })

    const history = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const errs = validate(formValue);

        if (formValue.buttonDisabled) {
            return;
        }

        if (errs.length > 0) {
            setFormValue({
                ...formValue,
                errors: errs,
                buttonDisabled: false
            });
            return;
        } else {
            setFormValue({
                ...formValue,
                errors: errs,
                buttonDisabled: true
            });
            if (term == null) {
                term = "other";
                console.log("changing to other ...");
            }
            let data = {
                fullname: formValue.fullname,
                phoneNumber: formValue.phone,
                email: formValue.email,
                location: formValue.location,
                background: formValue.statement,
                utmSource: term
            }
            axios.post(baseUrl+'/api/v1/consultation/signup', data)
                .then((response) => {
                    history("/contact");
                }).catch((err) => {
                console.log('FAILED', err);
            });
        }
    }

    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    const validate = (formValue) => {
        let errors = [];

        if (formValue.fullname.length === 0) {
            errors.push("Name can't be empty");
        }
        if (formValue.email.length === 0) {
            errors.push("Email can't be empty");
        }
        if (formValue.email.split("").filter(x => x === "@").length !== 1) {
            errors.push("Email should contain @");
        }
        if (formValue.location.length === 0 || formValue.phone.length === 0) {
            errors.push("Please fill out all the required fields");
        }
        return errors;
    }

    return (
        <div className="bg-gradient-to-b from-purple-600 to-indigo-700 w-full">
            <div className="w-full flex items-center justify-center my-12 pt-2">
                <div className="top-40 bg-white shadow rounded py-12 lg:px-28 px-8">
                    <FormTitle title='Sign up for your free consultation!' />
                    <form>
                        <div className="md:flex items-center mt-10">
                            <Input label='Full Name' name='fullname' type='name'
                                   value={formValue.fullname}
                                   placeholder='Enter your full name'
                                   onChange={handleChange} size='half'
                            />
                            <Input label='Phone number' name='phone' type='phone'
                                   value={formValue.phone}
                                   placeholder='What is your phone number?'
                                   onChange={handleChange} size='half'
                            />
                        </div>
                        <div className="md:flex items-center mt-10">
                            <Input label='Your current location' name='location' type='name'
                                   value={formValue.location}
                                   placeholder='Enter your city'
                                   onChange={handleChange} size='half'
                            />
                            <Input label='Email Address' name='email' type='email'
                                   value={formValue.email}
                                   placeholder='Enter your email address'
                                   onChange={handleChange} size='half'
                            />
                        </div>
                        <TextArea name='statement' value={formValue.statement}
                              label='Short statement about yourself'
                              placeholder='Tell about yourself, your current status, achievements, and future plans.'
                              onChange={handleChange}
                        />
                        <SubmitAgreement />
                        <ErrorsDisplay errors={formValue.errors} />
                        <SubmitButton onClick={handleSubmit} disabled={formValue.buttonDisabled} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FreeConsultation;