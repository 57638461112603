import React from "react";
import { FaUserCheck, FaCalendarAlt, FaUniversity, FaPenFancy } from "react-icons/fa";
import featuredImg from '../../images/featured.png'

const Feature = () => {
    const iconSize = 33;
    return (
        <div>
            <div className="mt-20">
                <div className="lg:flex items-stretch justify-between">
                    <div className="xl:w-1/2 lg:w-8/12">
                        <p className="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800">Why choose us</p>
                        <p className="text-lg leading-6 text-gray-600 mt-4 lg:w-7/12 w-full">What makes us different</p>
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mt-14">
                            <div className="flex items-center">
                                <div className="w-16 h-16 relative">
                                    <div className="absolute bottom-0 right-0 bg-indigo-50 rounded-md w-16 h-16 -mb-4 -mr-2" />
                                    <div className="relative z-20 text-white bottom-0 left-0 bg-indigo-700 rounded-md w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                        <FaUserCheck size={iconSize} />
                                    </div>
                                </div>
                                <div className="flex items-start flex-col ml-6 pt-8">
                                    <h2 className="text-base font-semibold leading-4 text-gray-800">Learning About You</h2>
                                    <p className=" text-base leading-6 mt-2 text-gray-600">We discover your goals and identify your strengths.</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-16 h-16 relative">
                                    <div className="absolute bottom-0 right-0 bg-indigo-50 rounded-md w-16 h-16 -mb-4 -mr-2" />
                                    <div className="relative z-20 text-white bottom-0 left-0 bg-indigo-700 rounded-md w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                        <FaCalendarAlt size={iconSize} />
                                    </div>
                                </div>
                                <div className="flex items-start flex-col ml-6 pt-8">
                                    <h2 className="text-base font-semibold leading-4 text-gray-800">Building Your Roadmap</h2>
                                    <p className=" text-base leading-6 mt-2 text-gray-600">We create a personalized roadmap for every student.</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-16 h-16 relative">
                                    <div className="absolute bottom-0 right-0 bg-indigo-50 rounded-md w-16 h-16 -mb-4 -mr-2" />
                                    <div className="relative z-20 text-white bottom-0 left-0 bg-indigo-700 rounded-md w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                        <FaUniversity size={iconSize} />
                                    </div>
                                </div>
                                <div className="flex items-start flex-col ml-6 pt-8">
                                    <h2 className="text-base font-semibold leading-4 text-gray-800">Choosing Your Universities</h2>
                                    <p className=" text-base leading-6 mt-2 text-gray-600">We identify the schools that match your profile best.</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-16 h-16 relative">
                                    <div className="absolute bottom-0 right-0 bg-indigo-50 rounded-md w-16 h-16 -mb-4 -mr-2" />
                                    <div className="relative z-20 text-white bottom-0 left-0 bg-indigo-700 rounded-md w-16 h-16 flex items-center justify-center mt-2 mr-3">
                                        <FaPenFancy size={iconSize} />
                                    </div>
                                </div>
                                <div className="flex items-start flex-col ml-6 pt-8">
                                    <h2 className="text-base font-semibold leading-4 text-gray-800">Perfecting Your Application</h2>
                                    <p className=" text-base leading-6 mt-2 text-gray-600">We submit your strongest possible application.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-2/5 w-full lg:mt-0 mt-6">
                        <img src={featuredImg} alt="Featured" className="w-full obejct-fit object-center object-fill h-full rounded-2xl" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feature;