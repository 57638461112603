import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/logo-black.svg';
import RedirectButton from './RedirectButton';
import { FaTimes } from "react-icons/fa";

const Navbar = () => {
    
    const toggleMenu = () => {
        const menu = document.querySelector(".mobile-menu");
        menu.classList.toggle("hidden");
    }

    return (
        <nav class="bg-white shadow-md z-10">
            <div class="max-w-7xl mx-auto px-4">
                <div class="flex justify-between relative">
                    <div class="flex space-x-7">
                        <a href="/" class="flex items-center py-4 px-2">
                            <img src={logo} alt="Logo" class="h-14 w-58 mr-2" />
                        </a>
                    </div>
                    <div class="hidden md:flex items-center space-x-4">
                        <NavLink to="/" className="py-4 px-2 font-semibold">
                            Home
                        </NavLink>
                        <NavLink to="/programs" className="py-4 px-2 hover:text-purple-500 transition duration-300 font-semibold">
                            Programs
                        </NavLink>
                        <a href={"https://jeffulmasov.notion.site/jeffulmasov/Careers-Globstudy-fbc143965f47497cb4b2ea3f23d7e2e3"}
                           className="py-4 px-2 hover:text-purple-500 transition duration-300 font-semibold">
                            Careers
                        </a>
                        <NavLink to="/about" className="py-4 px-2 hover:text-purple-500 transition duration-300 font-semibold">
                            About
                        </NavLink>
                    </div>
                    <div class="md:hidden flex items-center" onClick={toggleMenu}>
                        <button class="outline-none menu-button">
                            <svg class="w-6 h-6 text-gray-500" 
                            x-show="! showMenu" 
                            fill="none" 
                            stroke-linecap="round"
                            stroke-linejoin="round" 
                            stroke-width="2" 
                            viewBox="0 00 24 24" 
                            stroke="currentColor">
                                <path d="m4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="hidden mobile-menu w-full bg-white rounded-b-xl absolute ml-0 z-40 shadow-md">
                        <ul className="pt-12 pb-8 px-12">
                            <div className="flex justify-between">
                                <div className="font-medium text-3xl pb-6">
                                    Menu
                                </div>
                                <div onClick={toggleMenu}>
                                    <button>
                                        <FaTimes size={35} />
                                    </button>
                                </div>
                            </div>
                            <li className="mb-3">
                                <NavLink to="/" className="font-semibold text-lg">
                                    Home
                                </NavLink>
                            </li>
                            <li className="mb-3">
                                <NavLink to="/programs" className="hover:text-purple-500 transition duration-300 text-lg font-semibold">
                                    Programs
                                </NavLink>
                            </li>
                            <li className="mb-3">
                                <a href={"https://jeffulmasov.notion.site/jeffulmasov/Careers-Globstudy-fbc143965f47497cb4b2ea3f23d7e2e3"}
                                   className="hover:text-purple-500 transition duration-300 text-lg font-semibold">
                                    Careers
                                </a>
                            </li>
                            <li className="">
                                <NavLink to="/about" className="hover:text-purple-500 transition duration-300 text-lg font-semibold">
                                    About
                                </NavLink>
                            </li>
                            <RedirectButton
                                link="/consultation"
                                btnText="Free Consultation"
                            />
                        </ul>
                    </div>
                    <div class="hidden md:flex">
                        <RedirectButton
                            link="/consultation"
                            btnText="Free Consultation"
                        />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;