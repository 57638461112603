import kamramImg from '../images/testimonials/kamrambek.jpg';
import abduImg from '../images/testimonials/abdurakhmon.jpg';
import sardorImg from '../images/testimonials/sardor.jpg';

const testimonials_data = [
    {
        "id": 1,
        "fullname": "Sardor Allayarov",
        "title": "Hungarian Scholarship Winner",
        "comment": "I know that Globstudy have the information and their mentors are the best. But the fact that they give you so much confidence - that is just incredible.",
        "photoUrl": sardorImg
    },
    {
        "id": 2,
        "fullname": "Abdurakhmon Kazi",
        "title": "FLEX Program Finalist 2021",
        "comment": "My mentors at Globstudy were so friendly and helped me submit an application that I would never have been able to do my own. Globstudy opened so many doors for me.",
        "photoUrl": abduImg
    },
    {
        "id": 3,
        "fullname": "Kamrambek Isoev",
        "title": "Oxford Essay Scholarship Holder",
        "comment": "The most important elements Globstudy helped me with was my essays. Globstudy gave very detailed feedback on my essays which helped me win the scholarship.",
        "photoUrl": kamramImg
    }
]

export default testimonials_data;