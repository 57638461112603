import React from 'react';
import RedirectButton from '../components/common/RedirectButton';

const ThankYouPage = () => {
    return (
        <div className="bg-gradient-to-b from-purple-600 to-indigo-700 w-full">
            <div className="w-full flex items-center justify-center my-12 pt-2">
                <div className="top-40 bg-white shadow rounded-xl py-16 lg:px-28 px-12">
                    <p className="md:text-4xl text-xl font-bold leading-7 text-center text-gray-700">Thank You!</p>
                    <p className="pt-6">We will get back to you shortly</p>
                    <RedirectButton link="/" btnText="Go to Home Page" />
                </div>
            </div>
        </div>
    )
}

export default ThankYouPage;