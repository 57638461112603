import React, { useState } from "react";
import PersonCard from "../cards/PersonCard";
import team_data from "../../data/team_data";

const OurTeam = () => {
    const [teamMembers] = useState(team_data);

    return (
        <div className="mt-16">
            <div className="flex justify-center mx-auto pt-16">
                <div>
                    <p className="text-gray-500 text-lg text-center font-normal pb-3">Our Team</p>
                    <h1 className="xl:text-4xl text-3xl text-center text-gray-800 font-extrabold pb-6 w-full mx-auto">
                        Meet Our Leaders
                    </h1>
                </div>
            </div>
            <div className="w-full pt-10">
                <div className="mx-auto">
                    <div className="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                        {teamMembers.map(person => (
                            <PersonCard
                                person={person}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurTeam;
