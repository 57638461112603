import React, { useState } from 'react';

const PersonCard = (props) => {
    const [person] = useState(props.person);

    return (
        <div className="xl:w-1/3 sm:w-full md:w-3/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div className="rounded-2xl overflow-hidden shadow-md bg-white">
                <div className="absolute -mt-20 w-full flex justify-center">
                    <div className="h-32 w-32">
                        <img src={person.image} alt="" className="rounded-full object-cover h-full w-full shadow-md" />
                    </div>
                </div>
                <div className="px-6 mt-16">
                    <div className="font-bold text-3xl text-center pb-1">{person.name}</div>
                    <p className="text-gray-800 text-md text-center">{person.title}</p>
                    <p className="text-center text-gray-600 text-base pt-4 pb-10 font-normal">{person.description}</p>
                </div>
            </div>
        </div>
    )
}

export default PersonCard;