import React from 'react';
import FreeConsultationSection from '../components/forms/FreeConsultation';

const FreeConsultation = () => {

    return (
        <div>
            <FreeConsultationSection />
        </div>
    );
}

export default FreeConsultation;