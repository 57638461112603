import React, { useState } from 'react';
import PricingFeatures from './PricingFeatures';
import { Link } from 'react-router-dom';

const PriceSection = (props) => {

    const [program] = useState(props.program);
    const plan1 = program.pricing[0];
    const plan2 = program.pricing[1];

    return (
        <div>
        <section class="px-2 xl:px-0">
            <div class="py-12 px-2 mx-auto">
                <div class="flex flex-col lg:items-center justify-center w-full">
                    <h2 class="text-gray-800 dark:text-white lg:text-3xl text-2xl font-bold leading-7 flex justify-center">Our Pricing</h2>
                </div>
                <div class="flex items-center justify-center w-full mx-auto">
                    <div class="pt-14 w-full">
                        <div class="mx-auto">
                            <div class="flex flex-wrap justify-around sm:justify-center">
                                <div class="w-full lg:w-4/12 md:w-1/2 sm:w-full px-6">
                                    <div class="py-5 px-8 bg-white border border-gray-200shadow rounded-lg text-left">
                                        <h2 class="text-2xl text-indigo-700 font-semibold mb-8">{plan1.name}</h2>
                                        <ul class="flex flex-col mb-6">
                                            <PricingFeatures plan={plan1} />
                                        </ul>
                                        <p class="text-base text-indigo-700 relative pl-3">
                                            <span class="font-light text-2xl">$</span>
                                            <span class="text-4xl font-semibold">{plan1.price}</span>
                                        </p>
                                        <Link to="/programs/signup">
                                            <button class="mt-4 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold">Choose</button>
                                        </Link>
                                    </div>
                                </div>
                                <div class="w-full lg:w-4/12 md:w-1/2 sm:w-full px-6">
                                    <div class="py-5 px-8 bg-indigo-700 border border-gray-200 shadow rounded-lg text-left">
                                        <h2 class="text-2xl text-white font-semibold mb-8">{plan2.name}</h2>
                                        <ul class="flex flex-col mb-6">
                                            <PricingFeatures plan={plan2} />
                                        </ul>
                                        <div class="flex justify-between">
                                            <div>
                                                <p class="text-base text-white relative pl-3">
                                                    <span class="font-light text-2xl">$</span>
                                                    <span class="text-4xl font-semibold">{plan2.price}</span>
                                                </p>
                                            </div>
                                            <div class="pt-3 text-yellow-200">
                                                <Link to="/programs/signup" className="hover:text-yellow-300">Financial Aid Available</Link>
                                            </div>
                                        </div>
                                        <Link to="/programs/signup">
                                            <button class="mt-4 w-full text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100 px-8 py-3 text-base font-semibold">Try</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <style>
            {`
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
        </style>
    
        </div>
    )
}

export default PriceSection;