import turkeyImg from '../../images/programs/turkey.png';
import europeFlag from "../../images/flags/european-union.png";

const turkey_details = {
    id: 3,
    idName: "turkiye",
    name: "Turkiye Scholarships",
    subtitle: "Mentorship program",
    shortDescription: "Study in Turkey for free",
    requirements: ["English language proficiency", "At least 18 years old", "Be interested in studying Bachelor's or Master's or PhD", "Be intersted in studying in Turkey"],
    flags: [europeFlag],
    features: {
        country: "Europe",
        target: "18+ years old",
        length: "2-4 weeks",
        deadline: "December 30"
    },
    fullDescription: "Turkiye Scholarships mentorship aims to help students get admitted into top universities of Turkey and win a fully-funded scholarship. The program provides support with every aspect of the application, from guidance on collecting documents to visa application.",
    content: [
        {
            "id": 1,
            "title": "2-4 live meetings",
            "description": "You will meet with your mentor weekly to discuss the application progress."
        },
        {
            "id": 2,
            "title": "Application review",
            "description": "You will receive support and feedback on your application and essays."
        },
        {
            "id": 5,
            "title": "Essay feedback",
            "description": "Your essay will be reviewed and given feedback by an expert mentor."
        },
        {
            "id": 6,
            "title": "Help with Visa Application",
            "description": "Your mentor will also help you apply for Visa and travel to Hungary."
        }
    ],
    schedule: [
        {
            week: "Week 1",
            topic: "Introduction",
            description: "Create an action plan, start collecting documents"
        },
        {
            week: "Week 2-3",
            topic: "Working on Application",
            description: "Starting your application, writing and revising essays, learning about application tips"
        },
        {
            week: "Week 4",
            topic: "Final Review",
            description: "Revising and submitting the application"
        }
    ],
    pricing: [
        {
            name: "Starter",
            features: [
                {
                    feature: "2-4 live seminars",
                    includes: true
                },
                {
                    feature: "Application review",
                    includes: true
                },
                {
                    feature: "Top Tips for Essays",
                    includes: true
                },
                {
                    feature: "Essay Feedback and Correction",
                    includes: false
                },
                {
                    feature: "Admission Exams Preparation",
                    includes: false
                },
                {
                    feature: "Visa Support",
                    includes: false
                },
                {
                    feature: "Free Escort Service in Turkey",
                    includes: false
                },
                {
                    feature: "Access to database of 500+ scholarships",
                    includes: false
                },
                {
                    feature: "Free Assistance with other Programs",
                    includes: false
                },
            ],
            price: 29
        },
        {
            name: "Ultimate",
            features: [
                {
                    feature: "2-4 live seminars",
                    includes: true
                },
                {
                    feature: "Application review",
                    includes: true
                },
                {
                    feature: "Top Tips for Essays",
                    includes: true
                },
                {
                    feature: "Essay Feedback and Correction",
                    includes: true
                },
                {
                    feature: "Admission Exams Preparation",
                    includes: true
                },
                {
                    feature: "Visa Support",
                    includes: true
                },
                {
                    feature: "Free Escort Service in Turkey",
                    includes: true
                },
                {
                    feature: "Access to database of 500+ scholarships",
                    includes: true
                },
                {
                    feature: "Free Assistance with other Programs",
                    includes: true
                },
            ],
            price: 199
        }
    ],
    mentor: {
        name: "Abdulaziz",
        title: "Lead Mentor",
        achievements: [
            "JACAFA The Nippon Foundation Scholarship holder 2019",
            "Erasmus Mundus scholarship holder 2021",
            "Türkiye burslari & IsDB scholarship holder 2021"
        ]
    },
    image: turkeyImg
}

export default turkey_details;