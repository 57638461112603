import React, { useState } from 'react';
import SingleTestimonial from './SingleTestimonial';
import testimonials_data from '../../data/testimonials_data';

const Testimonials = () => {

    const [testimonials] = useState(testimonials_data);

    return (
        <div class="bg-gray-80 sm:px-4 md:px-28 lg:px-40 py-12">
            <h2 class="text-2xl leading-6 text-gray-800 text-center px-4">Testimonials</h2>
            <h1 class="lg:text-5xl md:text-4xl text-2xl font-semibold px-4 leading-10 text-gray-800 mt-6 text-center">What our students say</h1>
            <div class="mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 py-20 gap-6">
                {testimonials.map(testimonial => (
                    <SingleTestimonial 
                        key={testimonial.id}
                        testimonial={testimonial}
                    />
                ))}
            </div>
    
        </div>
    )
}

export default Testimonials;