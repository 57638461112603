import React, { useState } from "react";
import events_data from "../../data/events_data";
import EventsList from "./EventsList";

const EventsContainer = () => {

    const [state] = useState({
        events: events_data,
    })

    return (
        <div>
            <h2>Header about events</h2>
            <EventsList title="Upcoming Events" />
            <EventsList title="Past Events" />
        </div>
    )
}

export default EventsContainer;