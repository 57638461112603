import React from 'react';
import heroImg from '../../images/hero.png';

const Hero = () => {
    return (
        <div>  
        <section>
            <div class="w-full relative pb-10 px-6 xl:px-0">
                <div class="pt-20 lg:flex items-center relative z-10 container mx-auto">
                    <div class="w-full lg:w-1/2 h-full lg:pr-10 xl:pr-0">
                        <img aria-label="people smiling" class="mx-auto" src={heroImg}  alt="people smiling"/>
                    </div>
                    <div  role="contentinfo"  class="w-full lg:w-1/2 h-full">
                        <h1 tabindex="0" class="text-indigo-700 text-4xl lg:text-5xl font-black mb-8">#1 University Scholarships Consulting Company</h1>
                        <p tabindex="0" class="lg:w-3/4 text-gray-800 font-regular mb-8">
                            Breaking the barriers in college admissions and scholarship applications.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default Hero;