import React, { useState } from 'react';
import Hero from '../components/marketing/Hero';
import Stats from '../components/marketing/Stats';
import Feature from '../components/marketing/Feature';
import Testimonials from '../components/testimonial/Testimonials';
import CtaGeneral from '../components/marketing/CtaGeneral';
import OurServices from '../components/marketing/OurServices';
import CtaContact from '../components/marketing/CtaContact';

const Home = () => {

    const [state] = useState({
        cta: {
            title: "Follow our News Channel",
            description: "Receive the recent news and information about scholarships",
            link: "https://t.me/globstudy",
            actionText: "Subscribe"
        }
    })

    return (
        <div>
            <Hero />
            <Stats />
            <OurServices />
            <CtaContact />
            <div className="mx-auto pb-20 bg-gray-80 sm:px-2 md:px-28 lg:px-40">
                <Feature />
            </div>
            <CtaGeneral cta={state.cta}/>
            <Testimonials />
        </div>
    )
}

export default Home;