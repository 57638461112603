import jeffImg from '../images/team/jeff.jpg';
import russImg from '../images/team/russ.jpg';
import umidImg from '../images/team/umid.jpg';

const team_data = [
    {
        name: "Rustam Temirov",
        title: "CTO, Global Scholarships Expert",
        description: "Rustam is a UGRAD 2021 finalist, Stipendium Hungaricum scholarship holder, and Google Developers Group Organizer. He is currently studying Computer Science at ELTE University.",
        image: russImg
    },
    {
        name: "Jafarbek Ulmasov",
        title: "Co-founder and CEO",
        description: "Jafarbek is a recipient of $240,000 scholarship at Duke University and Duke Kunshan University where he is now completing Double Degree program in Data Science.",
        image: jeffImg
    },
    {
        name: "Umid Avchiev",
        title: "Co-founder, US College Admissions Expert",
        description: "Umid is a certified pilot and full scholarship holder at Western Michigan University where he is completing his degree in Aviation Flight Science.",
        image: umidImg
    }
]

export default team_data;