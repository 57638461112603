import React, {useState} from "react";

const ProgramSchedule = (props) => {
    const [schedule] = useState(props.schedule)
    return (
        <div className="w-full py-12 flex flex-col justify-center">
            <div class="flex justify-center">
                <h2 class="text-gray-800 dark:text-white lg:text-3xl text-2xl font-bold leading-7">Program schedule</h2>
            </div>
            <div className="bg-white shadow md:px-10 py-4 mt-8 mx-auto rounded-3xl w-full">
                <table className="whitespace-nowrap overflow-x-auto block">
                    <thead>
                        <tr className="h-16 text-sm leading-none text-gray-800">
                            <th className="font-medium text-left pl-4">Week</th>
                            <th className="font-medium text-left pl-12">Topic</th>
                            <th className="font-medium text-left pl-12 pr-4" style={{wordWrap: "breakWord", maxWidth: "600px"}}>Todos with mentor</th>
                        </tr>
                    </thead>
                    <tbody className="w-full">
                        {schedule.map(item => (
                            <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-200">
                                <td className="pl-4">
                                    <p className="font-medium">{item.week}</p>
                                </td>
                                <td className="pl-12">
                                    <p className="text-sm font-normal leading-none text-gray-800">{item.topic}</p>
                                </td>
                                <td className="pl-12 pr-4">
                                    <p className="font-normal" style={{wordWrap: "breakWord", maxWidth: "600px"}}>{item.description}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProgramSchedule;