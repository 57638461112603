import React from 'react';
import ProgramsContainer from '../components/program/ProgramsContainer';

const Programs = () => {
    return (
        <div>
            <ProgramsContainer />
        </div>
    )
}

export default Programs;