import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { send } from 'emailjs-com';
import { FaTimes } from 'react-icons/fa';

const ProgramSignupForm = (props) => {

    const [formValue, setFormValue] = useState({
        fullname: '',
        email: '',
        school: '',
        location: '',
        tgUsername: '',
        programName: '',
        paymentPlan: '',
        age: '',
        essay: '',
        buttonDisabled: false,
        errors: []
    })

    const programs = [
        {
            label: "Accelerator",
            value: "Accelerator"
        },
        {
            label: "Global UGRAD",
            value: "Ugrad"
        },
        {
            label: "Turkiye Scholarships",
            value: "Turkiye"
        },
        {
            label: "Stipendium Hungaricum",
            value: "Hungaricum"
        },
        {
            label: "Erasmus Mundus",
            value: "Erasmus"
        },
        {
            label: "Applying to US college",
            value: "UnitedStates"
        },
        {
            label: "General Mentorship",
            value: "General"
        }
    ];

    const history = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formValue.buttonDisabled) {
            return;
        }

        const errs = validate(formValue);

        if (errs.length > 0) {
            setFormValue({
                ...formValue,
                errors: errs,
                buttonDisabled: false
            });
            return;
        } else {
            setFormValue({
                ...formValue,
                errors: errs,
                buttonDisabled: true
            });
            send(
                'service_ff67hyp',
                'template_c4gzwgb',
                formValue,
                'user_iXquPS8j0NLz9Gu1ys6X8'
            )
            .then((response) => {
                history("/thankyou");
            })
            .catch((err) => {
                console.log('FAILED', err);
            });
        }
    }

    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    const validate = (formValue) => {
        const errors = [];

        if (formValue.fullname.length === 0) {
            errors.push("Name can't be empty");
        }
        if (formValue.email.length === 0) {
            errors.push("Email can't be empty");
        }
        if (formValue.email.split("").filter(x => x === "@").length !== 1) {
            errors.push("Email should contain @");
        }
        if (formValue.school.length === 0 || formValue.location.length === 0 || formValue.tgUsername.length === 0) {
            errors.push("Please fill out all the required fields");
        }
        if (formValue.programName.length === 0) {
            errors.push("Choose one program");
        }
        if (formValue.paymentPlan.length === 0) {
            errors.push("Choose one of the plans");
        }

        return errors;
    }

    return (
        <div className="bg-gradient-to-b from-purple-600 to-indigo-700 w-full">
            <div className="w-full flex items-center justify-center my-12 pt-2">
                <div className="top-40 bg-white shadow rounded py-12 lg:px-28 px-8">
                    <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">Sign Up for Mentorship program</p>
                    <div className="md:w-full mt-12">
                        <h3 className="text-xl font-semibold">Section 1 - Personal Information</h3>
                    </div>
                    <div className="md:flex items-center mt-12">
                        <div className="md:w-72 flex flex-col">
                            <label className="text-base font-semibold leading-none text-gray-800">Full Name</label>
                            <input name="fullname" value={formValue.fullname} onChange={handleChange} tabIndex={0} arial-label="Please input name" type="name" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Enter your fullname" />
                        </div>
                        <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 sm:mt-8">
                            <label className="text-base font-semibold leading-none text-gray-800">Email Address</label>
                            <input name="email" value={formValue.email} onChange={handleChange} tabIndex={0} arial-label="Please input email address" type="email" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Enter your email address" />
                        </div>
                    </div>
                    <div className="md:flex items-center mt-8">
                        <div className="md:w-72 flex flex-col">
                            <label className="text-base font-semibold leading-none text-gray-800">Your current school</label>
                            <input name="school" value={formValue.school} onChange={handleChange} tabIndex={0} arial-label="Please input company name" type="name" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Where do you study now?" />
                        </div>
                        <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 sm:mt-8">
                            <label className="text-base font-semibold leading-none text-gray-800">City, Country</label>
                            <input name="location" value={formValue.location} onChange={handleChange} tabIndex={0} arial-label="Please input country name" type="name" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Your current location" />
                        </div>
                    </div>
                    <div className="md:w-full flex flex-col mt-8">
                        <label className="text-base font-semibold leading-none text-gray-800">Select Mentorship program</label>
                        <select name="programName" value={formValue.programName} onChange={handleChange} className="form-select block w-full text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-3 bg-gray-100 border rounded border-gray-200 placeholder-gray-400">
                            {programs.map((program) => (
                                <option value={program.value}>{program.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="md:w-full flex flex-col mt-8">
                        <label className="text-base font-semibold leading-none text-gray-800">Choose your Plan</label>
                        <select name="paymentPlan" value={formValue.paymentPlan} onChange={handleChange} className="form-select block w-full text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-3 bg-gray-100 border rounded border-gray-200 placeholder-gray-400">
                            <option value="Starter">Starter Guide</option>
                            <option value="Ultimate">Ultimate Guide</option>
                        </select>
                    </div>
                    <div className="md:w-full flex flex-col mt-8">
                        <label className="text-base font-semibold leading-none text-gray-800">Phone number</label>
                        <input name="tgUsername" value={formValue.tgUsername} onChange={handleChange} tabIndex={0} arial-label="Please enter your phone number" type="username" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-3 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="Type your phone number" />
                    </div>
                    <div className="md:w-full flex flex-col mt-8">
                        <label className="text-base font-semibold leading-none text-gray-800">Your age</label>
                        <input name="age" value={formValue.age} onChange={handleChange} tabIndex={0} arial-label="Please input company name" type="number" className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-3 bg-gray-100 border rounded border-gray-200 placeholder-gray-400" placeholder="How old are you?" />
                    </div>

                    <div className="md:w-full mt-20 mb-10">
                        <h3 className="text-xl font-semibold">Section 2 - Financial Aid Application <span className="font-normal">(Optional)</span></h3>
                        <p className="text-xs mt-2">This section is only for those who want to receive financial aid in amount of 50%-100% of the cost.</p>
                    </div>
                    <div>
                        <div className="w-full flex flex-col mt-8">
                            <label className="text-base leading-none text-gray-800"><span className="font-semibold">Tell us about your greatest achievement</span> (~100 words)</label>
                            <textarea name="essay" value={formValue.essay} onChange={handleChange} tabIndex={0} aria-label="leave a message" type="name" className="h-36 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400 resize-none" defaultValue={""} />
                        </div>
                    </div>
                    <p className="text-xs leading-3 text-gray-600 mt-4">By clicking submit you agree to our terms of service, privacy policy and how we use data as stated</p>
                    <div>
                            {formValue.errors.map(err => (
                                <div className="flex text-center">
                                    <FaTimes className="text-red-600 text-xl pt-1" />
                                    <p key={err} className="pb-1">{err}</p>
                                </div>
                            ))}
                        </div>
                    <div className="flex items-center justify-center w-full mt-4">
                            <button onClick={handleSubmit} disabled={formValue.buttonDisabled} type="submit" class="focus:outline-none active:bg-black hover:opacity-90 w-48 h-12 text-md font-medium text-white bg-gradient-to-l from-blue-700 to-blue-800 rounded-3xl">
                                {formValue.buttonDisabled ? 'Sending...' : 'Submit'}
                            </button>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramSignupForm;