import React, {useState} from 'react';

const ProgramContent = (props) => {
    const [content] = useState(props.content);

    return (
        <div>
        <div class="mt-12">
            <div>
                <h2 class="text-gray-800 dark:text-white lg:text-3xl text-2xl font-bold leading-7 flex justify-center">Program content</h2>
            </div>
            <div class="text-gray-800 dark:text-white lg:text-base text-sm leading-normal">
                <div tabindex="0" aria-label="group of cards" class="focus:outline-none mt-12 flex flex-wrap justify-center gap-10 px-4">
                {content.map(feature => (
                  <div tabindex="0" aria-label="card 1" class="focus:outline-none flex sm:w-full md:w-5/12 pb-8">
                    <div class="w-20 h-20 relative mr-5">
                        <div class="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
                        <div class="absolute text-white bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                            <span class="text-3xl">{feature.id}</span>
                        </div>
                    </div>
                    <div class="w-10/12">
                        <h2 tabindex="0" class="focus:outline-none text-lg font-bold leading-tight text-gray-800">{feature.title}</h2>
                        <p tabindex="0" class="focus:outline-none text-base text-gray-600 leading-normal pt-2">{feature.description}</p>
                    </div>
                </div>
                ))}
                </div>
            </div>
        </div>
        </div>
    )
}

export default ProgramContent;