import React from "react";
import SingleProgram from './SingleProgram';

const ProgramList = (props) => {

    return (
        <div class="mx-auto py-12 bg-gray-80 sm:px-4 md:px-28 lg:px-40">
            <div class="flex justify-center text-4xl font-semibold pb-12">
                Our Programs
            </div>
            <div class="grid grid-cols-2 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-10 mx-auto">
                {props.programs.map(program => (
                    <SingleProgram
                        key={program.id}
                        program={program}
                    />
                ))}
            </div>
        </div>
    )
}

export default ProgramList;