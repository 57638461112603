import React, { Fragment } from 'react';
import './App.css';
import Home from './views/Home';
import Programs from './views/Programs';
import About from './views/About';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './components/common/Navbar';
import FreeConsultation from './views/FreeConsultation';
import Footer from './components/common/Footer';
import ScrollToTop from './components/common/ScrollToTop';
import ProgramSignup from './views/ProgramSignup';
import ThankYouPage from './views/ThankYouPage';
import Contact from './views/Contact';
import Event from './views/Event';
import EventRegistered from './views/EventRegistered';
import Events from "./views/Events";
import ProgramDetails from "./views/ProgramDetails";

function App() {
  return (
    <div className="App">
      <Router>
        <Fragment>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route exact path='' element={<Home />} />
            <Route exact path='/programs' element={<Programs />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/programs/:idName' element={<ProgramDetails />} />
            <Route exact path='/consultation' element={<FreeConsultation />} />
            <Route exact path='/programs/signup' element={<ProgramSignup />} />
            <Route exact path='/thankyou' element={<ThankYouPage />} />
            <Route exact path='/contact' element={<Contact />} />
            <Route exact path='/events' element={<Events />} />
            <Route exact path='/event' element={<Event />} />
            <Route exact path='/event/registered' element={<EventRegistered />} />
          </Routes>
          <Footer />
        </Fragment>
      </Router>
    </div>
  )
}

export default App;