import React from "react";

const SubmitAgreement = () => {
    return (
        <p className="text-xs leading-3 text-gray-600 my-4">
            By clicking submit you agree to our terms of service, privacy policy and how we use data as stated
        </p>
    )
}

export default SubmitAgreement;