import React from 'react';
import { Link } from 'react-router-dom';
import cambridgeCampusImgLg from '../../images/cambridge-campus-lg.jpg';
import cambridgeCampusImgSm from '../../images/cambridge-campus-sm.jpg';

const CtaContact = () => {
    return (
        <div>
            <div className="2xl:mx-auto 2xl:container md:px-20 px-2 md:py-12 py-9">
                <div className="relative rounded-md">
                    <img src={cambridgeCampusImgLg} alt="city view" className="w-full h-full rounded-md  object-center object-fill absolute md:block hidden" />
                    <img src={cambridgeCampusImgSm} alt="city view" className="w-full h-full rounded-md absolute object-center object-fill md:hidden" />
                    <div className="text-xl relative bg-gradient-to-r from-indigo-700 to-transparent  w-full h-full z-40 top-0 md:p-16 p-6 flex flex-col justify-between rounded-md ">
                        <div>
                            <h1 className="md:text-5xl text-3xl font-bold md:leading-10 leading-9 text-white sm:w-auto w-64">Act Before It's Too Late</h1>
                            <p className="text-base leading-6 text-white xl:w-5/12 lg:w-8/12 md:w-10/12  2xl:pr-12 mt-4">Sign up for a free consultation with our expert mentors, and get a chance to learn about scholarships available to you. It is absolutely free of charge!</p>
                        </div>
                        <div className="md:mt-12 mt-20">
                            <Link to="/consultation">
                                <button class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:opacity-90 w-48 h-12 text-lg font-medium text-indigo-700 bg-gradient-to-l from-indigo-50 to-indigo-100 rounded">Sign Up Now</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtaContact;