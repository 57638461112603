import React from 'react';

const TextArea = (props) => {
    let containerClass = 'md:w-150 flex flex-col mt-8 mx-2';
    if (props.size == 'half') {
        containerClass = 'md:w-72 flex flex-col mt-8 mx-2';
    }

    return (
        <div className={containerClass}>
            <label className="text-base leading-none text-gray-800">
                <span className="font-semibold">
                    {props.label}
                </span>
            </label>
            <textarea name={props.name} value={props.value} onChange={props.onChange} tabIndex={0} type="text"
                      className="h-36 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700
                      mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-400 resize-none"
                      placeholder={props.placeholder} />
        </div>
    )
}

export default TextArea;