import acceleratorImg from '../../images/programs/accelerator.png';
import usFlag from "../../images/flags/united-states.png";
import ukFlag from "../../images/flags/united-kingdom.png";
import canadaFlag from "../../images/flags/canada.png";
import europeFlag from "../../images/flags/european-union.png";

const accelerator_details = {
    id: 1,
    idName: "accelerator",
    name: "Accelerator",
    subtitle: "Mentorship program",
    shortDescription: "The ultimate guide to Scholarship applications",
    requirements: ["English language proficiency (B2+)", "Between 14 and 28 years old", "Be interested in academic scholarships"],
    flags: [usFlag, ukFlag, canadaFlag, europeFlag],
    features: {
        country: "US, UK, ...",
        target: "Anyone",
        length: "5 weeks",
        deadline: "December 18"
    },
    fullDescription: "Globstudy Accelerator is a one-month mentorship program for high schoolers, university students or graduates. The program prepares students for admissions and scholarship programs at top universities. Participants who successfully complete the program are expected to study at top 1000 schools worldwide with full or partial scholarships.",
    content: [
        {
            "id": 1,
            "title": "8 interactive seminars",
            "description": "You will learn about scholarships, writing essays, interviews, university majors, admission tests and leadership skills."
        },
        {
            "id": 2,
            "title": "Application review",
            "description": "You will receive support and feedback on your application and essays."
        },
        {
            "id": 3,
            "title": "Exclusive guide to Commonapp",
            "description": "If you are applying for US schools, then you will receive help with navigating and completing your Commonapp profile."
        },
        {
            "id": 4,
            "title": "Top Tips for Essays",
            "description": "Your mentor will host hands-on workshops on writing motivation letters and personal statements."
        },
        {
            "id": 5,
            "title": "IELTS / SAT Test Preparation Kit",
            "description": "You will get advising and unlimited resources for admission and language tests."
        },
        {
            "id": 6,
            "title": "Assistance with Scholarship Search",
            "description": "Your mentor will be assisting you to craft your college list and find your dream school."
        }
    ],
    schedule: [
        {
            week: "Week 1",
            topic: "Orientation and Introduction",
            description: "Learning about academic scholarships, majors, general application requirements."
        },
        {
            week: "Week 2",
            topic: "Essays and Letters",
            description: "Learning how to write motivation letters, personal statements, and how to get the best letter of recommendation."
        },
        {
            week: "Week 3",
            topic: "Study Guides for Tests and Exams",
            description: "Learning about best practices and preparation guides for tests, including IELTS, TOEFL, SAT, and GMAT."
        },
        {
            week: "Week 4",
            topic: "Extracurriculars and University Search",
            description: "Learning about extracurricular activities, their importance, and practising how to create a college list and find your dream school."
        },
        {
            week: "Week 5",
            topic: "Personal Consultations",
            description: "Creating a personalized roadmap for admissions, outlining your essays, determining your extracurricular activities, collecting documents."
        }
    ],
    pricing: [
        {
            name: "Starter",
            features: [
                {
                    feature: "8 live seminars",
                    includes: true
                },
                {
                    feature: "Application review",
                    includes: true
                },
                {
                    feature: "Exclusive guide to Commonapp",
                    includes: true
                },
                {
                    feature: "IELTS / SAT Test Preparation Kit",
                    includes: true
                },
                {
                    feature: "Personal consultations",
                    includes: true
                },
                {
                    feature: "Access to database of 500+ scholarships",
                    includes: true
                },
                {
                    feature: "Help with creating essay outlines",
                    includes: false
                },
                {
                    feature: "Detailed essay feedback and correction",
                    includes: false
                },
                {
                    feature: "Help with creating College List",
                    includes: false
                },
                {
                    feature: "Assistance with applying to 10 universities",
                    includes: false
                },
            ],
            price: 33
        },
        {
            name: "Ultimate",
            features: [
                {
                    feature: "8 live seminars",
                    includes: true
                },
                {
                    feature: "Application review",
                    includes: true
                },
                {
                    feature: "Exclusive guide to Commonapp",
                    includes: true
                },
                {
                    feature: "IELTS / SAT Test Preparation Kit",
                    includes: true
                },
                {
                    feature: "Personal consultations",
                    includes: true
                },
                {
                    feature: "Access to database of 500+ scholarships",
                    includes: true
                },
                {
                    feature: "Help with creating essay outlines",
                    includes: true
                },
                {
                    feature: "Detailed essay feedback and correction",
                    includes: true
                },
                {
                    feature: "Help with creating College List",
                    includes: true
                },
                {
                    feature: "Assistance with applying to 10 universities",
                    includes: true
                },
            ],
            price: 189
        }
    ],
    mentor: {
        name: "Jafarbek Ulmasov",
        title: "Lead Mentor",
        achievements: [
            "$240,000 Scholarship Holder",
            "Google Developers Group Samarkand Lead",
            "General Manager at Nextgen IT",
            "Writing Expert"
        ]
    },
    image: acceleratorImg
}

export default accelerator_details;