import {FaTimes} from "react-icons/fa";
import React from "react";

const ErrorsDisplay = (props) => {
    return (
        <div>
            {props.errors.map(err => (
                <div className="flex text-center">
                    <FaTimes className="text-red-600 text-xl pt-1" />
                    <p key={err} className="pb-1">{err}</p>
                </div>
            ))}
        </div>
    )
}

export default ErrorsDisplay;