import React from "react";

const FormTitle = (props) => {
    return (
        <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">
            {props.title}
        </p>
    )
}

export default FormTitle;