import React from 'react';
import { FaPhone, FaRegEnvelope, FaTelegram, FaFacebookF, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
    return (
        <div className="mt-16 mb-4">
            <div class="w-full lg:w-2/3 px-12 py-12 pb-16 mx-auto bg-blue-900 rounded-2xl">
			<div class="flex flex-col text-white">
                <h1 class="font-bold uppercase text-4xl my-4">Contact Globstudy</h1>
                <p class="text-gray-400">
                    Globstudy is online scholarships consulting company.
                </p>
                <div class="flex mt-4 w-2/3 lg:w-1/2">
                    <div class="flex flex-col mr-4 mt-1">
                        <FaPhone size={25} />
                    </div>
                    <div class="flex flex-col">
                        <h2 class="text-2xl">Call Us</h2>
                        <p class="text-gray-400">Tel: (+998) 99-557-89-33</p>
                    </div>
                </div>

                <div class="flex mt-4 w-2/3 lg:w-1/2">
                    <div class="flex flex-col mr-4 mt-1">
                        <FaRegEnvelope size={25} />
                    </div>
                    <div class="flex flex-col">
                        <h2 class="text-2xl">Email Us</h2>
                        <p class="text-gray-400">Email: info@globstudy.org</p>
                    </div>
                </div>
          
                <div class="flex mt-10 w-2/3 lg:w-1/2">
                    <div className="flex justify-start sm:justify-start xl:justify-end space-x-6 pr-2 xl:pr-0 lg:pr-0 md:pr-0 sm:pr-0">
                        <div>
                            <a href="https://www.facebook.com/globstudy.uz">
                                <FaFacebookF className="w-5 h-5" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/globstudy/">
                                <FaInstagram className="w-5 h-5" />
                            </a>
                        </div>
                        <div>
                            <a href="https://t.me/globstudy">
                                <FaTelegram className="w-5 h-5" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ContactUs;